<template>
  <div>
    <v-card flat outlined width="700" class="mt-6">
      <template>
        <v-data-table
          :headers="headers"
          :items="getAdminDefinitionsIncome"
          :search="search"
          :items-per-page="10"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          class="title primary--text"
        >
          <template v-slot:top>
            <v-card flat color="white" class="text-center">
              <v-card-title class="headline font-weight-normal primary--text">
                <v-row>
                  <v-col cols="7" md="7" class="text-left">
                    <v-toolbar-title class="headline primary--text"
                      >Income ({{ getAdminDefinitionsIncome.length }})<v-btn
                        @click="dialog = !dialog"
                        text
                        color="primary"
                      >
                        <v-icon color="success">mdi-shape-rectangle-plus</v-icon
                        >New</v-btn
                      >
                    </v-toolbar-title>
                  </v-col>

                  <v-col cols="5" md="5"
                    ><v-text-field
                      v-model="search"
                      prepend-inner-icon="mdi-account-search"
                      label="search"
                      outlined
                      hide-details
                      class="subtitle-1 primary--text"
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row class="mt-2">
                  <v-col cols="4" sm="4" md="4">
                    <v-checkbox
                      v-model="showSystemDefinitions"
                      label="show predefined"
                      color="success"
                      class="subtitle-1 primary--text mt-n3 mb-n4"
                      hide-details
                    >
                    </v-checkbox>
                  </v-col>
                  <v-col cols="4" sm="4" md="4">
                    <v-checkbox
                      v-model="showAdminDefinitions"
                      label="show individual"
                      color="success"
                      class="subtitle-1 primary--text mt-n3 mb-n4"
                      hide-details
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="4" sm="4" md="4"></v-col>
                </v-row>
              </v-card-title>

              <v-dialog persistent v-model="dialog" max-width="400px">
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-card>
                    <v-card-title class="ml-3">
                      <span class="headline mt-4">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" class="mt-n8">
                            <v-text-field
                              v-model="incomeSourceItem.class"
                              :rules="nameReqRules"
                              :disabled="incomeSourceItem.id < 999"
                              label="Income Class"
                              class="primary--text"
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" class="mt-n8">
                            <v-text-field
                              v-model.number="incomeSourceItem.growth"
                              :rules="numberRules"
                              label="estimated growth (p.a.)"
                              class="primary--text"
                              prefix="%"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions v-if="incomeSourceItem.id > 1000">
                      <v-btn
                        class="error--text mb-4 ml-6"
                        @click="deleteItem"
                        text
                        >Delete</v-btn
                      >
                      <v-spacer></v-spacer>
                      <v-btn class="primary--text mb-4" @click="close" text
                        >Cancel</v-btn
                      >
                      <div v-if="formTitle == 'Edit Income Item'">
                        <v-btn
                          color="success"
                          :disabled="!valid"
                          @click="updateItem"
                          class="mr-4 mb-4"
                          text
                          >Update</v-btn
                        >
                      </div>
                      <div v-else>
                        <v-btn
                          color="success"
                          :disabled="!valid"
                          @click="createItem"
                          class="mr-4 mb-4"
                          text
                          >Create</v-btn
                        >
                      </div>
                    </v-card-actions>
                    <v-card-actions v-else>
                      <v-spacer></v-spacer>
                      <v-spacer></v-spacer>
                      <v-spacer></v-spacer>
                      <v-btn class="primary--text mb-4" @click="close" text
                        >Cancel</v-btn
                      >
                      <div v-if="formTitle == 'Edit Income Item'">
                        <v-btn
                          color="success"
                          :disabled="!valid"
                          @click="updateItem"
                          class="mr-4 mb-4"
                          text
                          >Update</v-btn
                        >
                      </div>
                      <div v-else>
                        <v-btn
                          color="success"
                          :disabled="!valid"
                          @click="createItem"
                          class="mr-4 mb-4"
                          text
                          >Create</v-btn
                        >
                      </div>
                    </v-card-actions>
                  </v-card>
                </v-form>
              </v-dialog>
            </v-card>
          </template>

          <template v-slot:item.class="{ item }">
            <v-card-text
              v-if="item.id < 999"
              class="primary--text subtitle-2 ml-n4 my-n4"
            >
              {{ item.class }}</v-card-text
            >
            <v-card-text v-else class="success--text subtitle-2 ml-n4 my-n4">
              <v-btn
                text
                retain-focus-on-click
                color="success"
                class="text-capitalize subtitle-2  ml-n4"
                @click="editItem(item)"
                >{{ item.class }}</v-btn
              ></v-card-text
            >
          </template>

          <template v-slot:item.growth="{ item }">
            <v-card-text v-if="item.id < 999" class="my-n4"
              ><v-btn
                text
                retain-focus-on-click
                color="success"
                @click="editItem(item)"
                >{{ item.growth }}%</v-btn
              >
            </v-card-text>
            <v-card-text v-else class="my-n4">
              <v-btn
                text
                retain-focus-on-click
                color="success"
                @click="editItem(item)"
                >{{ item.growth }}%</v-btn
              >
            </v-card-text>
          </template>

          <template v-slot:no-data>
            <v-card flat>
              <v-card-text class="title font-weight-regular">
                There are no Items matching your choice ...
              </v-card-text>
            </v-card>
          </template>
        </v-data-table>
      </template>
    </v-card>
  </div>
</template>

<script>
import { authComputed } from '@/vuex/helpers.js'
import StaticDataService from '@/services/StaticDataService.js'

export default {
  name: 'adminDefinitionsIncome',

  data() {
    return {
      valid: true,
      showSystemDefinitions: true,
      showAdminDefinitions: true,
      incomeSourceItem: StaticDataService.getAdminDefinitionsIncomeSourceObject(),
      dialog: false,
      editedId: -1,
      sortBy: ['class', 'id'],
      sortDesc: [false, false],
      search: '',
      headers: [
        {
          text: 'Class',
          align: 'start',
          value: 'class',
          width: 230,
          class: 'subtitle-1 primary--text'
        },
        {
          text: 'estimated growth p.a.',
          align: 'left',
          value: 'growth',
          // width: 160,
          class: 'subtitle-1 primary--text'
        }
      ],
      nameReqRules: [
        v => !!v || 'Field is required',
        v =>
          !v || (v && v.length <= 30) || 'Name must be less than 30 characters'
      ],
      numberRules: [
        v => !v || /^(?=.*\d).+$/.test(v) || 'Requires numbers only'
      ],
      numberReqRules: [
        v => !!v || 'Number is required',
        v => !v || /^(?=.*\d).+$/.test(v) || 'Requires numbers only'
      ]
    }
  },
  methods: {
    createItem() {
      if (this.$refs.form.validate()) {
        this.incomeSourceItem.default = this.incomeSourceItem.growth
        this.$store
          .dispatch('createAdminDefinitionItem', this.definitionItem)
          .then(() => {
            this.close()
          })
      }
    },
    editItem(item) {
      this.incomeSourceItem = Object.assign({}, item)
      this.editedId = item.id
      this.dialog = true
    },
    updateItem() {
      if (this.$refs.form.validate()) {
        this.incomeSourceItem.default = this.incomeSourceItem.growth
        this.$store
          .dispatch('updateAdminDefinitionItem', this.definitionItem)
          .then(() => {
            this.close()
          })
      }
    },
    deleteItem() {
      this.$store
        .dispatch('deleteAdminDefinitionItem', this.definitionItem)
        .then(() => {
          this.close()
        })
    },
    close() {
      this.dialog = false
      this.incomeSourceItem = StaticDataService.getAdminDefinitionsIncomeSourceObject()
      this.$refs.form.resetValidation()
      this.editedId = -1
    }
  },
  computed: {
    formTitle() {
      return this.editedId === -1 ? 'New Income Item' : 'Edit Income Item'
    },
    definitionItem() {
      return { group: 'incomeSources', item: this.incomeSourceItem }
    },
    getAdminDefinitionsIncome() {
      let data = []
      // show system and/or tenant definitions according to checkbox setting
      if (this.showSystemDefinitions) {
        Object.entries(this.adminDefinitionsIncome).forEach(([, val]) => {
          // Id of predefined system definitions is always below 999
          if (val.id < 999) {
            data.push(val)
          }
        })
      }
      if (this.showAdminDefinitions) {
        Object.entries(this.adminDefinitionsIncome).forEach(([, val]) => {
          // Id of individual tenant definitions is always between 1001 and 9999
          if (val.id > 1000) {
            data.push(val)
          }
        })
      }
      return data
    },
    ...authComputed
  }
}
</script>

<style lang="scss" scoped></style>
