<template>
  <div>
    <v-card flat outlined width="700" class="mt-6">
      <template>
        <v-data-table
          :headers="headers"
          :items="getAdminDefinitionsAssets"
          :search="search"
          :items-per-page="10"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          class="title primary--text"
        >
          <template v-slot:top>
            <v-card flat color="white" class="text-center">
              <v-card-title class="headline font-weight-normal primary--text">
                <v-row>
                  <v-col cols="7" md="7" class="text-left">
                    <v-toolbar-title class="headline primary--text"
                      >Assets ({{ getAdminDefinitionsAssets.length }})<v-btn
                        @click="dialog = !dialog"
                        text
                        color="primary"
                      >
                        <v-icon color="success">mdi-shape-rectangle-plus</v-icon
                        >New</v-btn
                      >
                    </v-toolbar-title>
                  </v-col>

                  <v-col cols="5" md="5"
                    ><v-text-field
                      v-model="search"
                      prepend-inner-icon="mdi-account-search"
                      label="search"
                      outlined
                      hide-details
                      class="subtitle-1 primary--text"
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row class="mt-2">
                  <v-col cols="4" sm="4" md="4">
                    <v-checkbox
                      v-model="showSystemDefinitions"
                      label="show predefined"
                      color="success"
                      class="subtitle-1 primary--text mt-n3 mb-n4"
                      hide-details
                    >
                    </v-checkbox>
                  </v-col>
                  <v-col cols="4" sm="4" md="4">
                    <v-checkbox
                      v-model="showAdminDefinitions"
                      label="show individual"
                      color="success"
                      class="subtitle-1 primary--text mt-n3 mb-n4"
                      hide-details
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="4" sm="4" md="4"></v-col>
                </v-row>
              </v-card-title>

              <v-dialog persistent v-model="dialog" max-width="400px">
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-card>
                    <v-card-title class="ml-3">
                      <span class="headline mt-4">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" class="mt-n8">
                            <v-text-field
                              v-model="assetItem.class"
                              :rules="nameReqRules"
                              :disabled="assetItem.id < 999"
                              label="Asset Class"
                              class="primary--text"
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" class="mt-n8">
                            <v-text-field
                              v-model="assetItem.category"
                              :rules="nameReqRules"
                              :disabled="assetItem.id < 999"
                              label="Asset Category"
                              class="primary--text"
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" class="mt-n8">
                            <v-text-field
                              v-model.number="assetItem.growth"
                              :rules="numberRules"
                              label="estimated growth (p.a.)"
                              class="primary--text"
                              prefix="%"
                            ></v-text-field>
                          </v-col>

                          <v-col
                            v-if="
                              assetItem.class == 'Investment' &&
                                assetItem.id > 1000
                            "
                            cols="12"
                            class="mt-n8"
                            ><v-select
                              v-model="assetItem.type"
                              :items="assetTypeItems"
                              label="Asset Type - Income, Neutral or Growth"
                              class="primary--text"
                            ></v-select>
                            <v-select
                              v-model="assetItem.rank"
                              :items="investmentCategoryItems"
                              label="Asset Rank - Insert after predef. Category"
                              class="primary--text"
                            ></v-select>
                          </v-col>
                          <v-col v-else cols="12" class="mt-n8">
                            <v-select
                              v-model="assetItem.type"
                              :items="assetTypeItems"
                              label="Asset Type - Income, Neutral or Growth"
                              class="primary--text"
                            ></v-select>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions v-if="assetItem.id > 1000">
                      <v-btn
                        class="error--text mb-4 ml-6"
                        @click="deleteItem"
                        text
                        >Delete</v-btn
                      >
                      <v-spacer></v-spacer>
                      <v-btn class="primary--text mb-4" @click="close" text
                        >Cancel</v-btn
                      >
                      <div v-if="formTitle == 'Edit Asset Item'">
                        <v-btn
                          color="success"
                          :disabled="!valid"
                          @click="updateItem"
                          class="mr-4 mb-4"
                          text
                          >Update</v-btn
                        >
                      </div>
                      <div v-else>
                        <v-btn
                          color="success"
                          :disabled="!valid"
                          @click="createItem"
                          class="mr-4 mb-4"
                          text
                          >Create</v-btn
                        >
                      </div>
                    </v-card-actions>
                    <v-card-actions v-else>
                      <v-spacer></v-spacer>
                      <v-spacer></v-spacer>
                      <v-spacer></v-spacer>
                      <v-btn class="primary--text mb-4" @click="close" text
                        >Cancel</v-btn
                      >
                      <div v-if="formTitle == 'Edit Asset Item'">
                        <v-btn
                          color="success"
                          :disabled="!valid"
                          @click="updateItem"
                          class="mr-4 mb-4"
                          text
                          >Update</v-btn
                        >
                      </div>
                      <div v-else>
                        <v-btn
                          color="success"
                          :disabled="!valid"
                          @click="createItem"
                          class="mr-4 mb-4"
                          text
                          >Create</v-btn
                        >
                      </div>
                    </v-card-actions>
                  </v-card>
                </v-form>
              </v-dialog>
            </v-card>
          </template>

          <template v-slot:item.class="{ item }">
            <v-card-text
              v-if="item.id < 999"
              class="primary--text subtitle-2 ml-n4 my-n4"
            >
              {{ item.class }}</v-card-text
            >
            <v-card-text v-else class="success--text subtitle-2 ml-n4 my-n4">
              <v-btn
                text
                retain-focus-on-click
                color="success"
                class="text-capitalize subtitle-2  ml-n4"
                @click="editItem(item)"
                >{{ item.class }}</v-btn
              ></v-card-text
            >
          </template>

          <template v-slot:item.category="{ item }">
            <v-card-text
              v-if="item.id < 999"
              class="primary--text subtitle-2 my-n4"
            >
              {{ item.category }}</v-card-text
            >
            <v-card-text v-else class="my-n4">
              <v-btn
                text
                retain-focus-on-click
                color="success"
                class="text-capitalize subtitle-2 ml-n4"
                @click="editItem(item)"
                >{{ item.category }}</v-btn
              ></v-card-text
            >
          </template>

          <template v-slot:item.growth="{ item }">
            <v-card-text v-if="item.id < 999" class="my-n4"
              ><v-btn
                text
                retain-focus-on-click
                color="success"
                @click="editItem(item)"
                >{{ item.growth }}%</v-btn
              >
            </v-card-text>
            <v-card-text v-else class="my-n4">
              <v-btn
                text
                retain-focus-on-click
                color="success"
                @click="editItem(item)"
                >{{ item.growth }}%</v-btn
              >
            </v-card-text>
          </template>

          <template v-slot:no-data>
            <v-card flat>
              <v-card-text class="title font-weight-regular">
                There are no Items matching your choice ...
              </v-card-text>
            </v-card>
          </template>
        </v-data-table>
      </template>
    </v-card>
  </div>
</template>

<script>
import { authComputed } from '@/vuex/helpers.js'
import StaticDataService from '@/services/StaticDataService.js'

export default {
  name: 'adminDefinitionsAssets',

  data() {
    return {
      valid: true,
      showSystemDefinitions: true,
      showAdminDefinitions: true,
      assetItem: StaticDataService.getAdminDefinitionsAssetObject(),
      dialog: false,
      editedId: -1,
      sortBy: ['class', 'rank'],
      sortDesc: [false, false],
      search: '',
      headers: [
        {
          text: 'Class',
          align: 'start',
          value: 'class',
          width: 230,
          class: 'subtitle-1 primary--text'
        },
        {
          text: 'Category',
          align: 'left',
          value: 'category',
          width: 280,
          class: 'subtitle-1 primary--text'
        },
        {
          text: 'est. growth p.a.',
          align: 'center',
          value: 'growth',
          // width: 160,
          class: 'subtitle-1 primary--text'
        }
      ],
      assetTypeItems: [
        { text: 'Income', value: 'Income' },
        { text: 'Neutral', value: 'Neutral' },
        { text: 'Growth', value: 'Growth' }
      ],
      nameReqRules: [
        v => !!v || 'Field is required',
        v =>
          !v || (v && v.length <= 30) || 'Name must be less than 30 characters'
      ],
      numberRules: [
        v => !v || /^(?=.*\d).+$/.test(v) || 'Requires numbers only'
      ],
      numberReqRules: [
        v => !!v || 'Number is required',
        v => !v || /^(?=.*\d).+$/.test(v) || 'Requires numbers only'
      ]
    }
  },

  methods: {
    createItem() {
      if (this.$refs.form.validate()) {
        this.assetItem.default = this.assetItem.growth
        this.$store
          .dispatch('createAdminDefinitionItem', this.definitionItem)
          .then(() => {
            this.close()
          })
      }
    },

    editItem(item) {
      this.assetItem = Object.assign({}, item)
      this.editedId = item.id
      this.dialog = true
    },

    updateItem() {
      if (this.$refs.form.validate()) {
        this.assetItem.default = this.assetItem.growth
        this.$store
          .dispatch('updateAdminDefinitionItem', this.definitionItem)
          .then(() => {
            this.close()
          })
      }
    },

    deleteItem() {
      this.$store
        .dispatch('deleteAdminDefinitionItem', this.definitionItem)
        .then(() => {
          this.close()
        })
    },

    close() {
      this.dialog = false
      this.assetItem = StaticDataService.getAdminDefinitionsAssetObject()
      this.$refs.form.resetValidation()
      this.editedId = -1
    }
  },

  computed: {
    formTitle() {
      return this.editedId === -1 ? 'New Asset Item' : 'Edit Asset Item'
    },

    definitionItem() {
      return { group: 'assets', item: this.assetItem }
    },

    investmentCategoryItems() {
      return [
        { text: 'Cash', value: 1.5 },
        { text: 'New Zealand Fixed Interest', value: 2.5 },
        { text: 'International Fixed Interest', value: 3.5 },
        { text: 'Australasian Listed Property', value: 4.5 },
        { text: 'International Listed Property', value: 5.5 },
        { text: 'International Infrastructure', value: 6.5 },
        { text: 'Australasian Equities', value: 7.5 },
        { text: 'International Equities', value: 8.5 },
        { text: 'Other', value: 9.5 }
      ]
    },

    getAdminDefinitionsAssets() {
      let data = []
      // show system and/or tenant definitions according to checkbox setting
      if (this.showSystemDefinitions) {
        Object.entries(this.adminDefinitionsAssets).forEach(([, val]) => {
          // Id of predefined system definitions is always below 999
          if (val.id < 999) {
            data.push(val)
          }
        })
      }
      if (this.showAdminDefinitions) {
        Object.entries(this.adminDefinitionsAssets).forEach(([, val]) => {
          // Id of individual tenant definitions is always between 1001 and 9999
          if (val.id > 1000) {
            data.push(val)
          }
        })
      }
      return data
    },

    ...authComputed
  }
}
</script>

<style lang="scss" scoped></style>
