<template>
  <div>
    <the-admin-layout-header :headline="headline" />

    <v-card flat height="auto" class="d-flex align-stretch mt-6">
      <v-carousel
        v-model="caro"
        light
        height="auto"
        :show-arrows="false"
        hide-delimiters
        hide-delimiter-background
      >
        <v-carousel-item
          key="1"
          reverse-transition="fade-transition"
          transition="fade-transition"
          value="assets"
          class="mb-12"
        >
          <v-row class="mx-12 mt-n8">
            <v-col cols="12" sm="12" md="12" lg="12">
              <admin-definitions-assets />
            </v-col>
          </v-row>
        </v-carousel-item>

        <v-carousel-item
          key="2"
          reverse-transition="fade-transition"
          transition="fade-transition"
          value="liabilities"
          class="mb-12"
          ><v-row class="mx-12 mt-n8">
            <v-col cols="12" sm="12" md="12" lg="12">
              <admin-definitions-liabilities />
            </v-col>
          </v-row>
        </v-carousel-item>

        <v-carousel-item
          key="3"
          reverse-transition="fade-transition"
          transition="fade-transition"
          value="income"
          class="mb-12"
          ><v-row class="mx-12 mt-n8">
            <v-col cols="12" sm="12" md="12" lg="12">
              <admin-definitions-income />
            </v-col>
          </v-row>
        </v-carousel-item>

        <v-carousel-item
          key="4"
          reverse-transition="fade-transition"
          transition="fade-transition"
          value="expenses"
          class="mb-12"
          ><v-row class="mx-12 mt-n8">
            <v-col cols="12" sm="12" md="12" lg="12">
              <admin-definitions-spending />
            </v-col>
          </v-row>
        </v-carousel-item>

        <v-carousel-item
          key="5"
          reverse-transition="fade-transition"
          transition="fade-transition"
          value="risktypes"
          class="mb-12"
          ><v-row class="mx-12 mt-n8"
            ><v-col cols="12" sm="12" md="12" lg="12"
              ><admin-definitions-risk-types />
            </v-col>
          </v-row>
        </v-carousel-item>

        <v-carousel-item
          key="6"
          reverse-transition="fade-transition"
          transition="fade-transition"
          value="modelportfolios"
          class="mb-12"
          ><v-row class="mx-12 mt-n8"
            ><v-col cols="12" sm="12" md="12" lg="12"
              ><admin-definitions-model-portfolios />
            </v-col>
          </v-row>
        </v-carousel-item>
      </v-carousel>
    </v-card>
  </div>
</template>

<script>
import store from '@/vuex/store'
import { mapState } from 'vuex'
import { authComputed } from '@/vuex/helpers.js'
import TheAdminLayoutHeader from '@/components/TheAdminLayoutHeader.vue'
import AdminDefinitionsAssets from '@/components/AdminDefinitionsAssets.vue'
import AdminDefinitionsLiabilities from '@/components/AdminDefinitionsLiabilities.vue'
import AdminDefinitionsIncome from '@/components/AdminDefinitionsIncome.vue'
import AdminDefinitionsSpending from '@/components/AdminDefinitionsSpending.vue'
import AdminDefinitionsRiskTypes from '@/components/AdminDefinitionsRiskTypes.vue'
import AdminDefinitionsModelPortfolios from '@/components/AdminDefinitionsModelPortfolios.vue'

export default {
  name: 'adminDefinitions',

  // Navigation Route Guard to preload API Data before page is being shown
  beforeRouteEnter(routeTo, routeFrom, next) {
    // Fetch Tenant Default Definitions from DDB
    store.dispatch('fetchAdminDefinitions').then(() => {
      // making sure, when navigating to this page, the slider allways moves to "DEFINITIONS" ( tab = 1 )
      store.commit('SET_ADMIN_LAYOUT_TAB', 1)
      store.commit('SET_ADMIN_DEFINITIONS_CARO', routeTo.params.tabs)
      next()
    })
  },

  // Navigation Route Guard to refresh advisor definitions before this route is being left
  beforeRouteLeave(routeTo, routeFrom, next) {
    this.$store.dispatch('refreshAdvisorDefinitions')
    next()
  },

  components: {
    TheAdminLayoutHeader,
    AdminDefinitionsAssets,
    AdminDefinitionsLiabilities,
    AdminDefinitionsIncome,
    AdminDefinitionsSpending,
    AdminDefinitionsRiskTypes,
    AdminDefinitionsModelPortfolios
  },

  data() {
    return {
      definitions: {
        assets: { id: 1, name: 'Assets' },
        liabilities: { id: 2, name: 'Liabilities' },
        income: { id: 3, name: 'Income' },
        expenses: { id: 4, name: 'Expenses' },
        risktypes: { id: 5, name: 'Risk-Types' },
        modelportfolios: { id: 6, name: 'Model-Portfolios' }
      }
    }
  },

  computed: {
    headline() {
      return 'Company Definitions / ' + this.page.name
    },

    caro: {
      get() {
        return this.system.caroAdminDefinitions
      },
      set(definition) {
        this.$store.commit('SET_ADMIN_DEFINITIONS_CARO', definition)
        this.$router.push({
          name: 'adminDefinitions',
          params: { tabs: definition }
        })
      }
    },

    page() {
      let page = this.definitions[this.caro]
      return page
    },

    ...mapState(['system']),

    ...authComputed
  }
}
</script>

<style lang="scss" scoped></style>
