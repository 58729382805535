<template>
  <div>
    <v-card flat outlined width="700" class="mt-6">
      <template>
        <v-data-table
          :headers="headers"
          :items="getAdminDefinitionsRiskTypes"
          :items-per-page="10"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          class="title primary--text"
        >
          <template v-slot:top>
            <v-card flat color="white" class="text-center">
              <v-card-title class="headline font-weight-normal primary--text">
                <v-row>
                  <v-col cols="12" class="text-left">
                    <v-toolbar-title class="headline primary--text"
                      >Risk-Types ({{
                        getAdminDefinitionsRiskTypes.length
                      }})<v-btn @click="dialog = !dialog" text color="primary">
                        <v-icon color="success">mdi-shape-rectangle-plus</v-icon
                        >New</v-btn
                      >
                    </v-toolbar-title>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="6" sm="6" md="6">
                    <v-checkbox
                      v-model="showSystemDefinitions"
                      label="show predefined"
                      color="success"
                      class="subtitle-1 primary--text mt-n4 mb-n4"
                      hide-details
                    >
                    </v-checkbox>
                  </v-col>
                  <v-col cols="6" sm="6" md="6">
                    <v-checkbox
                      v-model="showAdminDefinitions"
                      label="show individual"
                      color="success"
                      class="subtitle-1 primary--text mt-n4 mb-n4"
                      hide-details
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-card-title>

              <v-dialog persistent v-model="dialog" max-width="500px">
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-card>
                    <v-card-title class="ml-3">
                      <span class="headline primary--text mt-4">{{
                        formTitle
                      }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-row v-if="riskTypeItem.id > 1000" class="pl-3">
                            <v-col cols="12" sm="6" md="6">
                              <v-text-field
                                v-model="riskTypeItem.name"
                                :rules="nameReqRules"
                                :disabled="riskTypeItem.id < 999"
                                label="Risk-Type Name"
                                class="primary--text ml-4"
                              ></v-text-field>
                              <v-select
                                v-model="riskTypeItem.rank"
                                :items="riskTypeItems"
                                label="Insert after predef. Risk-Type"
                                class="primary--text ml-4"
                              ></v-select>
                              <v-text-field
                                v-model.number="riskTypeItem.returnRate"
                                :rules="numberRules"
                                label="Est. return"
                                suffix="% p.a."
                                class="primary--text ml-4"
                                min="0"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6" class="mt-n8">
                            </v-col>
                          </v-row>

                          <v-row v-if="riskTypeItem.id < 999" class="pl-6">
                            <v-col cols="12" sm="4" md="4">
                              <v-text-field
                                v-model.number="riskTypeItem.returnRate"
                                :rules="numberRules"
                                label="Est. return"
                                suffix="% p.a."
                                class="primary--text"
                                min="0"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" class="pr-6">
                              <v-checkbox
                                v-model="riskTypeItem.description"
                                label="Default Description"
                                value="Default"
                                color="success"
                                class="subtitle-1 primary--text font-weight-medium mt-n4 mb-n4"
                                hide-details
                              ></v-checkbox>

                              <v-card-text
                                class="subtitle-1 primary--text mt-n2 ml-n3"
                                >{{ riskTypeItem.sysDescription }}</v-card-text
                              >
                            </v-col>
                          </v-row>

                          <v-row v-if="riskTypeItem.id < 999" class="pl-6 mt-2">
                            <v-col cols="12" sm="12" md="12">
                              <v-checkbox
                                v-model="riskTypeItem.description"
                                label="Modified Description"
                                value="Modified"
                                color="success"
                                class="subtitle-1 primary--text font-weight-medium mt-n4 mb-n4"
                                hide-details
                              ></v-checkbox>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" class="mt-n6">
                              <v-textarea
                                v-model="riskTypeItem.indDescription"
                                label="type your description here ..."
                                counter
                                maxlength="400"
                                outlined
                                full-width
                                class="mt-6 mr-8"
                                :disabled="
                                  riskTypeItem.description == 'Default'
                                "
                              ></v-textarea>
                            </v-col>
                          </v-row>

                          <v-row v-else>
                            <v-col cols="12" sm="12" md="12">
                              <v-card-text
                                class="subtitle-1 primary--text font-weight-medium ml-3 mt-n2"
                                >Individual Description</v-card-text
                              >
                            </v-col>
                            <v-col cols="12" sm="12" md="12" class="mt-n8">
                              <v-textarea
                                v-model="riskTypeItem.indDescription"
                                label="type your description here ..."
                                counter
                                maxlength="400"
                                full-width
                                outlined
                                :disabled="
                                  riskTypeItem.description == 'Default'
                                "
                                class="ml-6 mr-6"
                              ></v-textarea>
                            </v-col>
                          </v-row>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions v-if="riskTypeItem.id > 1000">
                      <v-btn
                        class="error--text mb-4 ml-6"
                        @click="deleteItem"
                        text
                        >Delete</v-btn
                      >
                      <v-spacer></v-spacer>
                      <v-btn class="primary--text mb-4" @click="close" text
                        >Cancel</v-btn
                      >
                      <div v-if="formTitle == formTitleEdit">
                        <v-btn
                          color="success"
                          :disabled="!valid"
                          @click="updateItem"
                          class="mr-4 mb-4"
                          text
                          >Update</v-btn
                        >
                      </div>
                      <div v-else>
                        <v-btn
                          color="success"
                          :disabled="!valid"
                          @click="createItem"
                          class="mr-4 mb-4"
                          text
                          >Create</v-btn
                        >
                      </div>
                    </v-card-actions>
                    <v-card-actions v-else>
                      <v-spacer></v-spacer>
                      <v-spacer></v-spacer>
                      <v-spacer></v-spacer>
                      <v-btn class="primary--text mb-4" @click="close" text
                        >Cancel</v-btn
                      >
                      <div v-if="formTitle == formTitleEdit">
                        <v-btn
                          color="success"
                          :disabled="!valid"
                          @click="updateItem"
                          class="mr-4 mb-4"
                          text
                          >Update</v-btn
                        >
                      </div>
                      <div v-else>
                        <v-btn
                          color="success"
                          :disabled="!valid"
                          @click="createItem"
                          class="mr-4 mb-4"
                          text
                          >Create</v-btn
                        >
                      </div>
                    </v-card-actions>
                  </v-card>
                </v-form>
              </v-dialog>
            </v-card>
          </template>

          <template v-slot:item.name="{ item }">
            <v-card-text class="success--text subtitle-2 ml-n4 my-n4">
              <v-btn
                text
                retain-focus-on-click
                color="success"
                class="text-capitalize subtitle-2  ml-n4"
                @click="editItem(item)"
                >{{ item.name }}</v-btn
              ></v-card-text
            >
          </template>

          <template v-slot:item.returnRate="{ item }">
            <v-card-text class="primary--text subtitle-2 ml-n4 my-n4">
              {{ item.returnRate }} %</v-card-text
            >
          </template>

          <template v-slot:item.description="{ item }">
            <v-card-text
              v-if="item.description == 'Modified' && item.id < 999"
              class="my-n4"
            >
              Modified
            </v-card-text>
            <v-card-text
              v-else-if="item.description == 'Modified' && item.id > 1000"
              class="my-n4"
            >
              Individual
            </v-card-text>
            <v-card-text v-else class="my-n4">Default </v-card-text>
          </template>

          <template v-slot:no-data>
            <v-card flat>
              <v-card-text class="title font-weight-regular">
                There are no Items matching your choice ...
              </v-card-text>
            </v-card>
          </template>
        </v-data-table>
      </template>
    </v-card>
  </div>
</template>

<script>
import { authComputed } from '@/vuex/helpers.js'
import StaticDataService from '@/services/StaticDataService.js'

export default {
  name: 'adminDefinitionsRiskTypes',

  data() {
    return {
      valid: true,
      showSystemDefinitions: true,
      showAdminDefinitions: true,
      riskTypeItem: StaticDataService.getAdminDefinitionsRiskTypeObject(),
      dialog: false,
      editedId: -1,
      sortBy: 'rank',
      sortDesc: false,
      headers: [
        {
          text: 'Risk-Type',
          align: 'start',
          value: 'name',
          width: 230,
          class: 'subtitle-1 primary--text'
        },
        {
          text: 'est. return',
          align: 'center',
          value: 'returnRate',
          width: 230,
          class: 'subtitle-1 primary--text'
        },
        {
          text: 'Description',
          align: 'center',
          value: 'description',
          width: 230,
          class: 'subtitle-1 primary--text'
        }
      ],
      nameReqRules: [
        v => !!v || 'Field is required',
        v =>
          !v || (v && v.length <= 30) || 'Name must be less than 30 characters'
      ],
      numberRules: [
        v => !v || /^(?=.*\d).+$/.test(v) || 'Requires numbers only'
      ],
      numberReqRules: [
        v => !!v || 'Number is required',
        v => !v || /^(?=.*\d).+$/.test(v) || 'Requires numbers only'
      ]
    }
  },

  methods: {
    createItem() {
      if (this.$refs.form.validate()) {
        this.$store
          .dispatch('createAdminDefinitionItem', this.definitionItem)
          .then(() => {
            this.close()
          })
      }
    },

    editItem(item) {
      this.riskTypeItem = Object.assign({}, item)
      this.editedId = item.id
      this.dialog = true
    },

    updateItem() {
      if (this.$refs.form.validate()) {
        if (this.riskTypeItem.description == null) {
          this.riskTypeItem.description = 'Default'
        }
        this.$store
          .dispatch('updateAdminDefinitionItem', this.definitionItem)
          .then(() => {
            this.close()
          })
      }
    },

    deleteItem() {
      this.$store
        .dispatch('deleteAdminDefinitionItem', this.definitionItem)
        .then(() => {
          this.close()
        })
    },

    close() {
      this.dialog = false
      this.riskTypeItem = StaticDataService.getAdminDefinitionsRiskTypeObject()
      this.$refs.form.resetValidation()
      this.editedId = -1
    },

    toggleCheckbox() {
      console.log('toggle')
      this.riskTypeItem.defDescription = !this.riskTypeItem.defDescription
      this.checkboxDefault = !this.checkboxDefault
      this.checkboxModified = !this.checkboxModified
    }
  },

  computed: {
    formTitleEdit() {
      return 'Edit "' + this.riskTypeItem.name + '" Risk-Type'
    },

    formTitle() {
      return this.editedId === -1 ? 'New Risk-Type Item' : this.formTitleEdit
    },

    definitionItem() {
      return { group: 'riskTypes', item: this.riskTypeItem }
    },

    riskTypeItems() {
      return [
        { text: 'Conservative', value: 1.5 },
        { text: 'Moderate', value: 2.5 },
        { text: 'Balanced', value: 3.5 },
        { text: 'Growth', value: 4.5 },
        { text: 'Aggressive', value: 5.5 },
        { text: 'Very Aggressive', value: 6.5 }
      ]
    },

    getAdminDefinitionsRiskTypes() {
      let data = []
      // show system and/or tenant definitions according to checkbox setting
      if (this.showSystemDefinitions) {
        Object.entries(this.adminDefinitionsRiskTypes).forEach(([, val]) => {
          // Id of predefined system definitions is always below 999
          if (val.id < 999) {
            data.push(val)
          }
        })
      }
      if (this.showAdminDefinitions) {
        Object.entries(this.adminDefinitionsRiskTypes).forEach(([, val]) => {
          // Id of individual tenant definitions is always between 1001 and 9999
          if (val.id > 1000) {
            data.push(val)
          }
        })
      }
      return data
    },

    ...authComputed
  }
}
</script>

<style lang="scss" scoped></style>
