<template>
  <div>
    <v-card flat outlined width="700" class="mt-6">
      <v-row>
        <v-col cols="8" md="8" class="text-left">
          <v-toolbar-title class="headline primary--text ml-4 mt-4"
            >Model-Portfolios ({{ modelPortfolioNames.length }})
            <v-btn
              @click="dialogNew = !dialogNew"
              text
              color="primary"
              class="mr-2"
            >
              <v-icon color="success">mdi-shape-rectangle-plus</v-icon
              >New</v-btn
            ></v-toolbar-title
          >
        </v-col>

        <v-col cols="4" md="4" class="text-right"> </v-col>
      </v-row>

      <v-tabs v-model="modelPortfolio" slider-color="success" class="mt-n4">
        <v-tab
          v-for="portfolio in modelPortfolioNames"
          :key="portfolio"
          :href="`#${portfolio}`"
          class="primary--text subtitle-1 mb-n3"
        >
          {{ portfolio }}
        </v-tab>
      </v-tabs>
      <v-divider class="success"></v-divider>

      <v-tabs-items v-model="modelPortfolio">
        <v-tab-item
          v-for="portfolio in modelPortfolioNames"
          :key="portfolio"
          :value="portfolio"
        >
          <template>
            <v-carousel hide-delimiters light>
              <v-carousel-item
                v-for="item in modelPortfolioItems"
                :key="item.id"
                reverse-transition="fade-transition"
                transition="fade-transition"
              >
                <v-card flat class="text-center mx-auto">
                  <v-row>
                    <v-col cols="8" md="8" class="text-left">
                      <v-toolbar-title class="headline primary--text ml-4 mt-4">
                        {{ modelPortfolio }} / {{ item.riskType }}
                      </v-toolbar-title>
                    </v-col>

                    <v-col cols="4" md="4" class="text-right"> </v-col>
                  </v-row>

                  <v-row
                    align="center"
                    justify="center"
                    class="d-flex text-left mt-8"
                  >
                    <div id="chart">
                      <apexchart
                        type="pie"
                        width="550"
                        height="550"
                        :key="item.id"
                        :options="item.chartOptions"
                        :series="item.series"
                      ></apexchart>
                    </div>
                  </v-row>
                  <v-row>
                    <v-col cols="8" md="8" class="text-left"> </v-col>

                    <v-col cols="4" md="4" class="text-right">
                      <v-btn
                        v-if="item.id > 999"
                        @click="dialogDel = !dialogDel"
                        text
                        class="error--text mt-12"
                      >
                        Delete</v-btn
                      >
                      <v-btn
                        v-if="item.id < 9999"
                        @click="editItem(item)"
                        text
                        class="mt-12 mr-2"
                      >
                        <v-icon color="success" class="title">mdi-pencil</v-icon
                        >EDIT</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-card>
              </v-carousel-item>
            </v-carousel>
          </template>
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <v-dialog persistent v-model="dialog" max-width="430px">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title class="ml-3">
            <span class="headline mt-4"
              >Edit {{ modelPortfolio }} /
              {{ modelPortfolioItem.riskType }}</span
            >
          </v-card-title>

          <v-card-text class="mt-6">
            <v-container>
              <template>
                <v-row
                  v-for="category in modelPortfolioItem.data"
                  :key="category.x"
                  class="mr-1"
                >
                  <v-col cols="10">
                    <v-subheader
                      class="primary--text subtitle-1 text-left mt-n11"
                    >
                      {{ category.x }}
                    </v-subheader>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      v-model.number="category.y"
                      :rules="numberRules"
                      class="primary--text text-right mt-n12"
                      suffix="%"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </template>

              <v-row>
                <v-col cols="6">
                  <v-subheader
                    class="primary--text subtitle-1 text-left font-weight-medium mt-n6"
                  >
                  </v-subheader>
                </v-col>
                <v-col cols="6">
                  <p
                    class="primary--text subtitle-1 text-right font-weight-medium mt-n6 mr-4"
                  >
                    Total: {{ getTotal }} %
                  </p>
                </v-col>
              </v-row>
              <v-row v-if="getTotal != 100" class="mr-1 mb-n6"
                ><p
                  class="error--text subtitle-1 text-right font-weight-medium ml-12 pl-6"
                >
                  Please adjust Total to 100%!
                </p>
                <v-col cols="12"> </v-col>
              </v-row>
              <v-row v-else class="mr-1 mb-n6"
                ><p
                  class="white--text subtitle-1 text-center font-weight-medium ml-12 pl-6"
                >
                  Please adjust Total to 100%!
                </p>
                <v-col cols="12"> </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions class="mt-n6">
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-btn class="primary--text mb-4" @click="close" text>Cancel</v-btn>

            <v-btn
              color="success"
              :disabled="!valid || getTotal != 100"
              @click="updateItem"
              class="mr-4 mb-4"
              text
              >Update</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog persistent v-model="dialogNew" max-width="400px">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title class="ml-3">
            <span class="headline mt-4">New Model Portfolio</span>
          </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="modelPortfolioName"
              :rules="nameReqRules"
              label="New Model Portfolio Name"
              class="primary--text mx-4"
            >
            </v-text-field>
            <v-select
              v-model="modelPortfolioTemplate"
              :items="modelPortfolioNames"
              label="use Template (optional)"
              class="primary--text mx-4"
            ></v-select>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-btn class="primary--text mb-4" @click="close" text>Cancel</v-btn>
            <v-btn
              color="success"
              :disabled="!valid"
              @click="createModelPortfolio"
              class="mr-4 mb-4"
              text
              >Create</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog persistent v-model="dialogDel" max-width="400px">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card class="pr-6">
          <v-card-title class="headline ml-3">
            <span class="headline mt-4"> Delete Model Portfolio </span>
          </v-card-title>
          <v-card-text class="subtitle-1 ml-3">
            Are you sure, you want to delete the
            {{ modelPortfolio }} Model-Portfolio?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-btn class="primary--text mb-4" @click="close" text>Cancel</v-btn>
            <v-btn
              color="error"
              :disabled="!valid"
              @click="deleteModelPortfolio"
              class="mb-4"
              text
              >Delete</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
// import store from '@/vuex/store'
import { authComputed } from '@/vuex/helpers.js'
import SystemService from '@/services/SystemService.js'
import StaticDataService from '@/services/StaticDataService.js'

export default {
  name: 'adminDefinitionsModelPortfolios',

  data() {
    return {
      valid: true,
      modelPortfolio: null,
      modelPortfolioName: null,
      modelPortfolioTemplate: null,
      dialog: false,
      dialogNew: false,
      dialogDel: false,
      modelPortfolioItem: StaticDataService.getAdminDefinitionsModelPortfolioObject(),
      editedId: -1,
      nameReqRules: [
        v => !!v || 'Field is required',
        v =>
          !v || (v && v.length <= 30) || 'Name must be less than 30 characters'
      ],
      numberRules: [v => !v || /^(?=.*\d).+$/.test(v) || 'error'],
      numberReqRules: [
        v => !!v || 'Number is required',
        v => !v || /^(?=.*\d).+$/.test(v) || 'Requires numbers only'
      ]
    }
  },

  methods: {
    createModelPortfolio() {
      if (this.$refs.form.validate()) {
        const promises = []

        // if template is chosen
        if (this.modelPortfolioTemplate) {
          // read riskTypes from chosen model one by one and create new model portfolio
          Object.entries(this.adminDefinitionsModelPortfolios).forEach(
            ([, obj]) => {
              if (obj.modelPortfolio == this.modelPortfolioTemplate) {
                this.modelPortfolioItem = JSON.parse(JSON.stringify(obj))
                console.log('Blueprint Nr. 2 ', this.modelPortfolioItem)
                this.modelPortfolioItem.id = SystemService.getRandomIdBetween(
                  1001,
                  9999
                )
                this.modelPortfolioItem.modelPortfolio = this.modelPortfolioName
                promises.push(
                  this.$store.dispatch(
                    'createAdminDefinitionItem',
                    this.definitionItem
                  )
                )
              }
            }
          )
          Promise.all(promises)
            .then(() => {
              console.log('created new Admin Model Portfolio from Template')
              this.close()
            })
            .catch(error => {
              console.error(error.message)
            })
        }

        // if no template is chosen (default)
        else {
          const riskTypes = []

          // retrieve all(default/individual) RiskTypes from Database
          Object.entries(this.adminDefinitionsRiskTypes).forEach(([, obj]) => {
            riskTypes.push(obj.name)
          })

          riskTypes.map(riskType => {
            this.modelPortfolioItem = StaticDataService.getAdminDefinitionsModelPortfolioObject()
            this.modelPortfolioItem.modelPortfolio = this.modelPortfolioName
            this.modelPortfolioItem.riskType = riskType
            promises.push(
              this.$store.dispatch(
                'createAdminDefinitionItem',
                this.definitionItem
              )
            )
          })

          Promise.all(promises)
            .then(() => {
              console.log('created new Admin Model Portfolio from scratch')
              this.modelPortfolioName = null
              this.close()
            })
            .catch(error => {
              console.error(error.message)
            })
        }
      }
    },

    deleteModelPortfolio() {
      const modelPortfolioItemIds = []
      // retrieve all Model Portfolio Items intended to delete from Database
      Object.entries(this.adminDefinitionsModelPortfolios).forEach(
        ([, obj]) => {
          if (obj.modelPortfolio == this.modelPortfolio) {
            modelPortfolioItemIds.push(obj.id)
          }
        }
      )
      let portfolio = { group: 'modelPortfolios', item: modelPortfolioItemIds }
      this.$store.dispatch('deleteAdminDefinitionItem', portfolio).then(() => {
        console.log('deleted Model Portfolio ')
        this.close()
      })
    },

    editItem(item) {
      this.editedId = item.id
      this.modelPortfolioItem = JSON.parse(JSON.stringify(item))
      delete this.modelPortfolioItem.series
      this.dialog = true
    },
    updateItem() {
      if (this.$refs.form.validate()) {
        this.$store
          .dispatch('updateAdminDefinitionItem', this.definitionItem)
          .then(() => {
            this.close()
          })
      }
    },
    deleteItem() {
      this.$store
        .dispatch('deleteAdminDefinitionItem', this.definitionItem)
        .then(() => {
          this.close()
        })
    },
    close() {
      this.dialog = false
      this.dialogNew = false
      this.dialogDel = false
      this.modelPortfolioItem = StaticDataService.getAdminDefinitionsModelPortfolioObject()
      this.$refs.form.resetValidation()
      this.editedId = -1
      this.modelPortfolioName = null
      this.modelPortfolioTemplate = null
    }
  },
  computed: {
    definitionItem() {
      return { group: 'modelPortfolios', item: this.modelPortfolioItem }
    },

    getTotal() {
      let total = 0
      Object.entries(this.modelPortfolioItem.data).forEach(([, obj]) => {
        total += isNaN(obj.y) || null ? 0 : obj.y
      })
      console.log('total = ', total)
      return total
    },

    modelPortfolioNames() {
      const portfolios = []
      Object.entries(this.adminDefinitionsModelPortfolios).forEach(
        ([, obj]) => {
          portfolios.push(obj.modelPortfolio)
        }
      )
      return Array.from(new Set(portfolios))
    },

    // extract tab portfolio data from all model portfolios, use ranked riskTypes (conservative to very aggressive
    // incl. individual ones) and extend with chart data parameters (series, labels) for charting
    modelPortfolioItems() {
      let items = []
      let baseData = JSON.parse(
        JSON.stringify(this.adminDefinitionsModelPortfolios)
      )
      Object.entries(this.adminDefinitionsRiskTypes).forEach(([, riskType]) => {
        Object.entries(baseData).forEach(([, val]) => {
          if (
            this.modelPortfolio == val.modelPortfolio &&
            riskType.name == val.riskType
          ) {
            const series = []
            const labels = []
            Object.entries(val.data).forEach(([, obj]) => {
              series.push(obj.y)
              labels.push(obj.x)
            })
            val.series = series
            val.chartOptions = {
              colors: [
                '#BBBBBB',
                '#828282',
                '#55677D',
                '#FFC46D',
                '#007EB9',
                '#FFAC31',
                '#31465F',
                '#FF9900',
                '#B71C1C'
              ],
              labels: labels,
              dataLabels: {
                enabled: false
              },
              yaxis: {
                type: 'numeric',
                labels: {
                  formatter: function(value) {
                    return value + ' %'
                  }
                }
              },
              legend: {
                show: true,
                showForNullSeries: true,
                position: 'right',
                formatter: function(seriesName, opts) {
                  return opts.w.globals.series[opts.seriesIndex] <= 9
                    ? [
                        '  ',
                        opts.w.globals.series[opts.seriesIndex],
                        ' % ',
                        seriesName
                      ]
                    : [
                        opts.w.globals.series[opts.seriesIndex],
                        ' %  ',
                        seriesName
                      ]
                },
                offsetY: 0,
                offsetX: -20
              },
              tooltip: {
                enabled: true
              }
            }
            items.push(val)
          }
        })
      })
      return items
    },

    ...authComputed
  }
}
</script>

<style lang="scss" scoped></style>
